<template>
  <div></div>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  name: "change-version",
  methods: {
    ...mapMutations("mobile", ["changeVersion"]),
  },
  mounted() {
    this.changeVersion(this.$route.params.version);
    if (this.$route.params.version === "web")
      this.$router.push({ name: "dashboard" });
    if (this.$route.params.version === "mobile")
      this.$router.push({ name: "mobileLayout" });
  },
};
</script>